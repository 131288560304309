module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"pl","configPath":"C:\\Users\\itsdz\\Documents\\Praca\\PWSFTviT\\Odszkolnic Deschool\\code\\odszkolnic_web\\i18n\\config.json"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"locales":"./i18n/locale","i18nextOptions":{"ns":["common","404","home","nav-menu","search","fullscreen"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"C:/Users/itsdz/Documents/Praca/PWSFTviT/Odszkolnic Deschool/code/odszkolnic_web/plugins/remark-vnlab-plugin","name":"remark-vnlab-plugin","id":"84e30652-f908-53ee-b977-ae526c0a16b6","version":"0.0.1","modulePath":"C:\\Users\\itsdz\\Documents\\Praca\\PWSFTviT\\Odszkolnic Deschool\\code\\odszkolnic_web\\plugins\\remark-vnlab-plugin\\index.js","pluginOptions":{"plugins":[]},"nodeAPIs":["createSchemaCustomization"],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"C:/Users/itsdz/Documents/Praca/PWSFTviT/Odszkolnic Deschool/code/odszkolnic_web/node_modules/gatsby-remark-orphans","id":"d5fbd8a4-985b-55e5-ad01-89cf43e7c035","name":"gatsby-remark-orphans","version":"1.0.7","modulePath":"C:\\Users\\itsdz\\Documents\\Praca\\PWSFTviT\\Odszkolnic Deschool\\code\\odszkolnic_web\\node_modules\\gatsby-remark-orphans\\dist\\index.js","pluginOptions":{"plugins":[],"silenceReporting":true,"disableDefaultLocaleResolver":true},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"rehypePlugins":[null],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"C:\\Users\\itsdz\\Documents\\Praca\\PWSFTviT\\Odszkolnic Deschool\\code\\odszkolnic_web","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["HK-Grotesk:n3,n4,n5,n7"],"urls":["/fonts/fonts.css","/fonts/blogger/BloggerSans-Bold.css","/fonts/blogger/BloggerSans-BoldItalic.css","/fonts/blogger/BloggerSans-Medium.css","/fonts/blogger/BloggerSans-MediumItalic.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Odszkolnić! Deschool!","short_name":"Odszkolnić! Deschool!","description":"“Odszkolnić! Deschool!” / publication accompanying the exhibition. Are different institutions of artistic education possible? And if not, what could alternative models look like? Exhibition at Arsenal Gallery in Bialystok.","start_url":"/","background_color":"#B7ACCE","theme_color":"#B7ACCE","display":"standalone","orientation":"portrait","icon":"C:\\Users\\itsdz\\Documents\\Praca\\PWSFTviT\\Odszkolnic Deschool\\code\\odszkolnic_web\\config/../static/images/favicon3.png","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/index.html","/en/index.html","/int/index.html"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
